/**
 * Set Time Out
 */
const SHOW_FOCUS_TIMEOUT = 30;

/**
 * Focus search form
 */
export const enhancer = el => {
  el.addEventListener('click', (e) => {
    setTimeout(() => {
      document.getElementsByClassName('search-form__input')[0].focus();
    }, SHOW_FOCUS_TIMEOUT);
  });
};
