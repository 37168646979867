/* eslint-disable no-return-assign */
import * as ibantools from 'ibantools';
import { debounce } from './util';

const NEXT_BUTTON_SELECTOR = '.js-next-button';
const PREVIOUS_BUTTON_SELECTOR = '.js-previous-button';

const DonationForm = container => {
  let currentTab = 0;

  const form = container.querySelector('form');

  const nextButton = container.querySelector(NEXT_BUTTON_SELECTOR);
  const previousBtn = container.querySelector(PREVIOUS_BUTTON_SELECTOR);

  const validateForm = () => form.checkValidity();
  const validateStep = tabpanel => {
    const inputs = tabpanel.querySelectorAll('input', 'select');
    return [...inputs].every(input => input.validity && input.validity.valid);
  };

  const disableButton = button => button.disabled = true;
  const enableButton = button => button.disabled = false;

  const hideButton = button => button.setAttribute('aria-hidden', 'true');
  const showButton = button => button.setAttribute('aria-hidden', 'false');

  const getCurrentTabpanel = () => {
    return form.querySelector('[role="tabpanel"][aria-hidden="false"]');
  };

  const getAmountRadioContainers = () => container.querySelectorAll('.js-amount-container');
  const getAmountRadios = () => container.querySelectorAll('[name="quantity"]');

  const updateNextButton = () => {
    const isLastTab = currentTab === 2;
    const currentTabpanel = getCurrentTabpanel();
    const finalStepLabel = nextButton.getAttribute('data-final-step-label') || 'Doneer nu';
    nextButton.innerHTML = isLastTab ? finalStepLabel : 'Volgende stap';
    const nextStepIsAllowed = isLastTab ? validateForm() : validateStep(currentTabpanel);
    const stateToggler = nextStepIsAllowed ? enableButton : disableButton;
    stateToggler(nextButton);

    // Delay change, since else the form might be submitted since the button
    // change happens in the same micro as the click itself.
    window.setTimeout(e => nextButton.type = isLastTab ? 'submit' : 'button', 0);
  };

  const updateStepIndicators = n => {
    const steps = container.querySelectorAll('[role="tab"]');
    [...steps].forEach((step, index) => {
      step.setAttribute('aria-selected', currentTab === index);
      step.setAttribute('aria-current', currentTab === index ? 'step' : 'false');
      step.parentNode.classList.toggle('is-current', currentTab === index);
    });
    steps[n].disabled = false;
    steps[n].parentNode.classList.add('has-passed');
  };

  const showTab = (n, focusOnFirstInput = true) => {
    const tabs = container.querySelectorAll('[role="tabpanel"]');
    [...tabs].forEach((tab, index) => tab.setAttribute('aria-hidden', currentTab !== index));

    const previousBtnToggler = (n === 0) ? hideButton : showButton;
    previousBtnToggler(previousBtn);

    updateNextButton();
    updateStepIndicators(n);

    if (focusOnFirstInput) {
      const input = getCurrentTabpanel().querySelector('input:not([type=hidden]),select');
      input.focus();
      input.parentNode.scrollIntoView({ behavior: "smooth" });
    }
  };

  const goToTab = n => {
    // Limit the tabs to the magic minimum and maximum.
    if (n < 0 || n > 2) {
      return;
    }
    // Don't go forward when it's not safe.
    if (n > currentTab && !validateStep(getCurrentTabpanel())) {
      return;
    }
    currentTab = n;
    showTab(currentTab);
  };

  const handleTabClick = clickedTab => {
    currentTab = parseInt(clickedTab.getAttribute('data-tab-index'), 10) - 1;
    showTab(currentTab);
  };

  const showNextTab = e => goToTab(currentTab + 1);
  const showPreviousTab = e => goToTab(currentTab - 1);

  const toggleCustomAmountField = e => {
    const customAmountField = container.querySelector('[name="custom_quantity"]');
    if (!customAmountField) {
      return;
    }
    const customAmountRadio = container.querySelector('[name="quantity"][value=""]');
    customAmountField.required = customAmountRadio.checked;
    customAmountField.value = customAmountRadio.checked ? customAmountField.value : '';

    // Prevent tabbing when the checkbox is unselected, else we'll get into a tab loop.
    customAmountField.setAttribute('tabindex', customAmountRadio.checked ? '' : '-1');
  };

  const checkCustomAmount = () => e => {
    const customAmountRadio = container.querySelector('[name="quantity"][value=""]');
    customAmountRadio.checked = true;
    toggleCustomAmountField();
    updateNextButton();
  };

  const postMailCheckboxChanged = checkbox => (e) => {
    const postmailDetailContainer = container.querySelector('.js-postmail-details');
    if (!postmailDetailContainer) {
      return;
    }
    const postmailRequired = checkbox.checked;
    const postmailInputs = postmailDetailContainer.querySelectorAll('input');
    postmailDetailContainer.setAttribute('aria-hidden', !postmailRequired);
    [...postmailInputs].forEach(input => { input.required = postmailRequired; });
    updateNextButton();
  };

  const handleIntervalChange = () => e => {
    const interval = e.target.options[e.target.selectedIndex].value;
    const radioContainers = getAmountRadioContainers();
    // Show/Hide the radio container based on the selected interval.
    [...radioContainers].forEach(radioContainer => {
      radioContainer.setAttribute('aria-hidden', radioContainer.getAttribute('data-interval') !== interval);
    });
    // Pre-select the first option.
    [...radioContainers]
      .find(radioContainer => radioContainer.getAttribute('data-interval') === interval)
      .querySelector('input').checked = true;
    // Trigger `toggleCustomAmountField`.
    toggleCustomAmountField();
  };

  const toggleIbanField = () => e => {
    const ibanContainer = container.querySelector('.js-iban-group');
    const ibanField = container.querySelector('[name="iban"]');
    if (!ibanContainer || !ibanField) {
      return;
    }
    const ibanRequired = container.querySelector('[name="payment-method"][value="SepaDirectDebit"]').checked;
    ibanField.required = ibanRequired;
    ibanContainer.setAttribute('aria-hidden', !ibanRequired);
    updateNextButton();
  };

  const verifyIban = input => e => {
    const iban = ibantools.electronicFormatIBAN(input.value);
    const isValid = ibantools.isValidIBAN(iban);
    input.setCustomValidity(isValid ? '' : 'Rekeningnummer is geen geldig IBAN nummer.');
  };

  const handleFormSubmit = () => e => {
    disableButton(container.querySelector(NEXT_BUTTON_SELECTOR));
  };

  const normalizeHashParameters = hash => hash.split('&').reduce((result, item) => {
    const parts = item.split('=');
    return { ...result, [parts[0]]: parts[1] };
  }, {});

  const handleHash = hash => {
    if (!hash) {
      return;
    }
    const parameters = normalizeHashParameters(hash.substring(1));
    if (!parameters.amount) {
      return;
    }
    const input = container.querySelector(`[name="quantity"][value="${parameters.amount}"]`);
    if (!input) {
      return;
    }
    input.checked = true;
  };

  return {
    init() {
      const intervalSelect = container.querySelector('[name="interval"]');
      if (intervalSelect) {
        intervalSelect.addEventListener('change', handleIntervalChange(container));
      }

      const amountRadios = getAmountRadios();
      [...amountRadios].forEach(radio => radio.addEventListener('change', toggleCustomAmountField));

      const customAmountField = container.querySelector('[name="custom_quantity"]');
      if (customAmountField) {
        customAmountField.addEventListener('click', checkCustomAmount());
      }

      const postMailCheckbox = container.querySelector('[name="subscription-postmail"]');
      postMailCheckbox.addEventListener('click', postMailCheckboxChanged(postMailCheckbox));

      const paymentMethodRadios = container.querySelectorAll('[name="payment-method"]');
      [...paymentMethodRadios].forEach(radio => radio.addEventListener('change', toggleIbanField()));

      const ibanField = container.querySelector('[name="iban"]');
      if (ibanField) {
        ibanField.addEventListener('change', verifyIban(ibanField));
        ibanField.addEventListener('keydown', debounce(verifyIban(ibanField), 50));
      }

      nextButton.addEventListener('click', showNextTab);
      previousBtn.addEventListener('click', showPreviousTab);

      container.querySelector('[role="tablist"]').addEventListener('click', e => {
        if (e.target.nodeName !== 'BUTTON' || e.target.disabled) {
          return;
        }
        handleTabClick(e.target);
        e.target.blur();
      });

      form.addEventListener('change', e => updateNextButton());
      form.addEventListener('keyup', debounce(updateNextButton, 150));
      form.addEventListener('keydown', e => {
        if ((e.key && e.key === 'Enter') || e.keyCode === 13) {
          showNextTab();
        }
      });
      form.addEventListener('submit', handleFormSubmit());

      showTab(currentTab, false);
      handleHash(window.location.hash);
    },
  };
};

export const enhancer = container => {
  const donationForm = DonationForm(container);
  donationForm.init();
};
