import { getDocWidth } from './responsive';

const BODY_ACTIVE_CLASS = 'has-active-site-nav';
const BODY_HEIGHT_CLASS = 'has-higher-site-nav';

const SITE_NAV_SWITCH = 1160;

const hasSiteNav = () => SITE_NAV_SWITCH > getDocWidth();

const toggle = (nav, forceHide = false) => {
  const shouldHide = forceHide || nav.getAttribute('aria-hidden') === 'false';

  const classListToggle = shouldHide ? 'remove' : 'add';
  document.body.classList[classListToggle](BODY_ACTIVE_CLASS);

  nav.setAttribute('aria-hidden', !!shouldHide);

  const tabindexToggle = shouldHide ? 'setAttribute' : 'removeAttribute';
  const links = nav.querySelectorAll(`a`);
  [...links].forEach(link => {
    link[tabindexToggle]('tabindex', '-1');
  });

  const buttons = document.querySelectorAll(`[aria-controls="${nav.id}"]`);
  [...buttons].forEach(button => {
    button.setAttribute('aria-expanded', !shouldHide);
  });
};

export const toggleHandler = (el, e) => {
  e.preventDefault();
  toggle(document.getElementById(el.getAttribute('aria-controls')));
  setTimeout(() => el.blur(), 0);
};

export const overlayHandler = (el, e) => {
  e.preventDefault();
  toggle(document.getElementById(el.getAttribute('aria-controls')));
};

const init = el => {
  toggle(el, true);
  document.documentElement.addEventListener('keyup', (e) => {
    if ((e.key && e.key === 'Escape') || e.keyCode === 27) {
      toggle(el);
    }
  });

  const nav = el.querySelector('nav');
  const docHeight = window.innerHeight || document.documentElement.clientHeight;
  if ((nav.offsetHeight + 170) > docHeight) {
    document.body.classList.add(BODY_HEIGHT_CLASS);
  }
};

const attachResizeListener = el => {
  const resizeHandler = () => {
    const check = () => {
      if (!hasSiteNav()) {
        return;
      }
      init(el);
      window.removeEventListener('resize', resizeHandler);
    };
    check();
  };
  window.addEventListener('resize', resizeHandler);
};

export const enhancer = el => {
  if (hasSiteNav()) {
    init(el);
  } else {
    attachResizeListener(el);
    toggle(el.querySelector('nav'), true);
  }
};
