// Import libraries and polyfills
import Promise from 'promise-polyfill';
import 'whatwg-fetch';
import 'classlist-polyfill';
import domready from 'domready';
import rafPolyfill from './polyfills/request-animation-frame';
import pictureFill from './polyfills/picture-fill';

// Handler and Enhancer utility
import handle from './modules/handle';
import enhance from './modules/enhance';
import loadFontTracker from './modules/load-font-tracker';

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as collage } from './modules/collage';
import { enhancer as externalLinks } from './modules/external-links';
import { enhancer as objectFit } from './modules/object-fit';
import { enhancer as quotePosition } from './modules/quote-position';
import { enhancer as scrollDownEnhancer } from './modules/scroll-down';
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as accessibleCollapser } from "./modules/accessible-collapser";
import { handler as classToggler } from './modules/class-toggler';
import { handler as gtmEventHandler, datalayerHandler } from './modules/gtm-event';
import { lazyMediaPlay, lazyMediaClose } from './modules/lazy-media';
import { handler as scrollDownHandler } from './modules/scroll-down';
import { toggleHandler as siteNavToggler } from './modules/site-nav';
import { overlayHandler as siteNavClose } from './modules/site-nav';

// Import enhancers
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as siteNav } from './modules/site-nav';
import { enhancer as donationFormEnhancer } from './modules/donation-form';
import { enhancer as donationFormSwitcher } from './modules/donation-form-switcher';
import { enhancer as formRedirect } from './modules/form-redirect';
import { enhancer as newsletterSignup } from './modules/newsletter-signup';
import { enhancer as searchFormFocus } from './modules/search-form-focus';
import { enhancer as quizForm } from './modules/quiz-form';

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change
  loadFontTracker(); // Load font license tracker

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;
  window.pictureFill = window.pictureFill || pictureFill;

  if (typeof window.Promise === 'undefined') {
    window.Promise = Promise;
  }
};

const main = () => {
  executeOnReady();
  handle({
    accessibleCollapser,
    classToggler,
    datalayerHandler,
    gtmEventHandler,
    lazyMediaPlay,
    lazyMediaClose,
    scrollDownHandler,
    siteNavToggler,
    siteNavClose,
  });
  enhance({
    collage,
    donationFormEnhancer,
    donationFormSwitcher,
    externalLinks,
    objectFit,
    formRedirect,
    gtmEventEnhancer,
    newsletterSignup,
    quotePosition,
    scrollDownEnhancer,
    searchFormFocus,
    siteNav,
    quizForm,
  });
};

domready(main);
