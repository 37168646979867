import { map } from './util';

const addPositionClass = (el) => {
  const quotes = el.querySelectorAll('.rich-text blockquote');
  map(quotes, (quote, i) => {
    const className = i % 2 === 0 ? 'rich-text__quote--right' : 'rich-text__quote--left';
    quote.classList.add(className);
  });
};

export const enhancer = (el) => {
  addPositionClass(el);
};
