import { matchesBreakpoint } from "./responsive";

const repositionQuote = (el) => {
  const wrapper = el.querySelector('.collage__wrapper');
  const thirdImage = el.querySelector('.collage__image:nth-of-type(3)');
  const quote = el.querySelector('blockquote');
  if (quote) {
    if (matchesBreakpoint('medium')) {
      wrapper.append(quote);
    } else {
      wrapper.insertBefore(quote, thirdImage);
    }
  }
};

export const enhancer = el => {
  repositionQuote(el);
  const resizeActions = () => {
    repositionQuote(el);
  };
  window.addEventListener('resize', e => resizeActions());
  window.addEventListener('orientationchange', e => resizeActions());
};
