import { map } from './util';

const addAttributesToLinks = (el) => {
  const links = el.querySelectorAll("a[href$='.pdf'], a[href$='.PDF'], a[href$='.doc'], a[href$='.DOC'], a[href$='.xls'], a[href$='.XLS']");
  map(links, link => {
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
  });
};

export const enhancer = (el) => {
  addAttributesToLinks(el);
};
