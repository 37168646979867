/**
 * Load MyFonts.net link with JavaScript instead of renderblocking @import
 */
export default () => {
  const head = document.querySelector("head");
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = "https://hello.myfonts.net/count/37eb5a";
  link.media = "all";
  head.appendChild(link);
};
