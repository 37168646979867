/* eslint-disable no-return-assign */
import { debounce } from './util';

const SUBMIT_BUTTON_SELECTOR = '.js-submit-button';

const QuizForm = form => {
  const submitButton = form.querySelector(SUBMIT_BUTTON_SELECTOR);
  const disableButton = button => button.disabled = true;
  const enableButton = button => button.disabled = false;

  const updateSubmitButton = () => {
    const stateToggler = form.checkValidity() ? enableButton : disableButton;
    stateToggler(submitButton);
  };

  return {
    init() {
      updateSubmitButton();

      form.addEventListener('change', e => {
        updateSubmitButton();
      });

      form.addEventListener('keyup', debounce(updateSubmitButton, 150));
    },
  };
};

export const enhancer = form => {
  const quizForm = QuizForm(form);
  quizForm.init();
};
