import { closest } from './util';

const TAB_SELECTOR = '.js-form-switcher-tab';
const TABPANEL_SELECTOR = '.js-form-switcher-tabpanel';

const DonationFormSwitcher = container => {
  const tabs = container.querySelectorAll(TAB_SELECTOR);
  const tabpanels = container.querySelectorAll(TABPANEL_SELECTOR);

  const paintTabs = current => {
    [...tabs].forEach(tab => tab.setAttribute('aria-selected', tab === current));
  };

  const paintTabpanels = current => {
    [...tabpanels].forEach(panel => panel.setAttribute('aria-hidden', panel !== current));
  };

  const handleTabClick = e => {
    e.preventDefault();
    const tab = closest(e.target, '[role="tab"]');
    const tabpanel = container.querySelector(`#${tab.getAttribute('aria-controls')}`);
    paintTabs(tab);
    paintTabpanels(tabpanel);
  };

  const isValidTarget = (target) => {
    return target === 'vaker' || target === 'eenmalig';
  };

  return {
    init() {
      [...tabs].forEach(tab => tab.addEventListener('click', handleTabClick));
      const target = window.location.hash.replace('#', '');
      if (isValidTarget(target)) {
        paintTabs(document.getElementById(`${target}-tab`));
        paintTabpanels(document.getElementById(`${target}`));
      }
    },
  };
};

export const enhancer = el => {
  const switcher = DonationFormSwitcher(el);
  switcher.init();
};
