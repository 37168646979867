import { closest } from './util';

const PLAYING_CLASS = 'is-playing-video';
const IFRAME_ATTRIBUTE = 'data-iframe-embed';
const isHeroEmbed = el => closest(el, '.video-hero');

const addCloseButton = el => {
  const button = document.createElement('button');
  button.classList.add('video-embed__close');
  button.setAttribute('data-handler', 'lazyMediaClose');
  button.setAttribute('aria-label', 'Close video');
  el.parentNode.appendChild(button);
};

const closeVideo = (el) => {
  const hero = closest(el, '.video-hero');
  const embedContainer = el.previousElementSibling;
  // embedContainer.innerHTML = '';
  el.parentNode.removeChild(el);
  hero.classList.remove(PLAYING_CLASS);
};

export const lazyMediaPlay = (el, e) => {
  e.preventDefault();
  const embedContainer = el.parentNode.querySelector('.js-video-container');
  if (isHeroEmbed(el)) {
    closest(el, '.video-hero').classList.add(PLAYING_CLASS);
    addCloseButton(el);
  } else {
    closest(el, '.video-embed').classList.add(PLAYING_CLASS);
  }
  embedContainer.innerHTML = el.getAttribute(IFRAME_ATTRIBUTE);
};

export const lazyMediaClose = (el, e) => {
  e.preventDefault();
  if (isHeroEmbed(el)) {
    closeVideo(el);
  }
};
