import objectFitImages from 'object-fit-images';

/**
 * Polyfill for object-fit
 * @see: https://github.com/bfred-it/object-fit-images/
 */
export const enhancer = (elm, e) => {
  if (!Modernizr.objectfit) {
    objectFitImages(elm);
  }
};
